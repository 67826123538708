function IndexPage() {
  return (
    <Base>
      <header>
        <h1>
          <SrText>Lesley Barber</SrText>
        </h1>
      </header>

      <main id="main-content" tabIndex="-1">
        <Films />
      </main>
    </Base>
  )
}

///////////////////////////////////////////////////////////////////////////////////

import React from 'react'

import Base from '../ui/Base'
import Films from '../ui/Films'
import { SrText } from '../ui/elements'

export default IndexPage
