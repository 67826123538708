// See: https://www.gatsbyjs.org/blog/2019-02-20-introducing-use-static-query/

function useFilmsData() {
  const { allFilmsYaml } = useStaticQuery(
    graphql`
      query {
        allFilmsYaml {
          edges {
            node {
              title
              slug
              homeImage {
                file {
                  childImageSharp {
                    fluid(maxWidth: 500) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                alt
                objPosition
              }
            }
          }
        }
      }
    `
  )

  return allFilmsYaml.edges
}

///////////////////////////////////////////////////////////////////////////////////

import { useStaticQuery, graphql } from 'gatsby'

export default useFilmsData

/*

import useFilmsData from '../data/useFilmsData'

const films = useFilmsData()

*/
